.widget {
    background-color: rgb(163, 163, 163);
    border-radius: 6px;
    border: 1px solid rgb(134, 134, 134);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: move;
    overflow: hidden;
  }
  .widget .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .widget .info .icon {
    color: white;
    margin-bottom: 16px;
  }
  .widget .info .type {
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .widget .info .name {
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
  }
  .widget .controls {
    position: absolute;
    font-family: 'Open Sans', sans-serif;
    top: 8px;
    right: 8px;
    justify-content: center;
    align-items: center;
    display: none;
  }
  .widget .delete,
  .widget .edit {
    display: flex;
    font-family: 'Open Sans', sans-serif;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    cursor: pointer;
  }

  .widget .edit {
    background: rgba(0, 0, 0, 0.6);
    margin-right: 8px;
  }

  .widget .delete {
    background: #ED1C24;
  }

  .widget:hover .controls {
    display: flex;
  }