.icon-with-badge {
    position: relative;
    display: inline-block;
    
    .icon-with-badge__badge {
        position: absolute;
        right: -8px;
        top: -4px;
        padding: 2px 5px;
        line-height: 12px;
    }
}