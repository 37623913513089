.Toastify__toast {
    background-color: #fff !important;
    color: #1F2D3D !important;
    padding: 5px !important;
}

.navItem {
    display: block;
    margin: 0;
    padding: 0.5rem 1rem;

    &.active,
    &:hover {
        box-shadow: none;
        background-color: #626c79;
        color: #fff;
    }

}

.new-navbar {
    overflow: auto;
    white-space: nowrap;

    li {
        display: inline-block;
        list-style: none;
    }
}

.spinner {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #ED1C24;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pageLoader {
    position: fixed;
    top: 0;
    //left: 0;
    bottom: 0;
    //right: 0;
    z-index: 1000;

}

.responsiveTable {
    //display: block;
    width: 100% !important;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch !important;
}

@media (max-width: 991.98px) {
    .responsiveTable {
        display: block !important;
    }
}

@media (max-width: 767.98px) {
    .responsiveTable {
        display: block !important;
    }
}

@media (max-width: 575.98px) {
    .responsiveTable {
        display: block !important;
    }
}


.react-grid-item.react-grid-placeholder {
    background: #3396ff;
    border-radius: 10px;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .react-grid-item.dropping {
    visibility: hidden;
  }

  .grid {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 95vh;
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 20px;
    color:rgb(255, 255, 255);
    border-radius: 10px;
    padding: 10px;
    display: grid;
  }
  
   .border.content {
    border-color: #3396ff;
    border-style: solid;
    border-width: 1.5px;
  }
  .border.content.blue {
    border-color: #4088d5;
  }
  .border.content.orange {
    border-color: #d98140;
  }
  .border.content.green {
    border-color: #22a45c;
  }
  .border.content.white {
    border-color: white;
  }
  
  .border.content:active {
    border-color: #4088d5;
  }

.react-datepicker-popper {
  z-index: 9999 !important;
}


.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
  background: none;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 6px;
  height: 6px;
  border-right: 2px solid rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
}

.drop_area {
  max-width: unset !important;
}