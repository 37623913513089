/* RC Sliders Overrides */
@import "./../variables.scss";

.rc-slider {
    .rc-slider-track {
        background-color: $primary;
    }

    .rc-slider-dot-active {
        border-color: $primary !important;
    }

    .rc-slider-handle {
        border-color: $primary;

        &:active {
            border-color: darken($primary, 10%);
            box-shadow: 0 0 5px darken($primary, 10%);
        }
        &:hover {
            border-color: darken($primary, 5%);
        }
    }

    .rc-slider-rail {
        background-color: $gray-400;
    }
    .rc-slider-dot {
        background-color: $white;
        border: 2px solid $gray-500;
    }
}
