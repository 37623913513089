/*@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";*/

@import "./../variables.scss";

.react-bootstrap-table th.sortable {
    white-space: nowrap;
}

.react-bootstrap-table th,
.react-bootstrap-table td {
    vertical-align: middle !important;
}

.react-bootstrap-table .table:not(.table-bordered) tbody tr:last-child td {
    border-bottom: 1px solid $table-border-color;
}

.react-bootstrap-table-pagination {
    padding: 0 0.75rem;
}

.react-bootstrap-table-pagination-list .pagination {
    justify-content: flex-end;
}
